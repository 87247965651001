import React from 'react';
import { List, Datagrid, Edit, Create, SimpleForm, Filter, TextField, 
        EditButton, ArrayInput, TextInput, SimpleFormIterator, ReferenceInput, 
        SelectInput, ReferenceField, ReferenceArrayField, SingleFieldList, ChipField,Pagination } from 'react-admin';
import BookIcon from 'material-ui/svg-icons/action/book';
import RichTextInput from 'ra-input-rich-text';

export const VendorProvidersIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        
        <TextInput label="Vendor" source="vendorid" ></TextInput>
        <TextInput label="Provider" source="providerid" ></TextInput>
    </Filter>
);

export const VendorProvidersList = (props) => (
    <List {...props} title="Autorizacija vendora i provajdera" filters={<PostFilter />}>
        <Datagrid rowClick="edit">
            <ReferenceField label="Vendor" source="vendorId" reference="vendorsforprovider" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceArrayField source="providersId" label="Dozvoljeni provajderi" reference="providersall">
                <SingleFieldList>
                        <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <EditButton basePath="/VendorProviders" />
        </Datagrid>
    </List>
);

const VendorProvidersTitle = ({ record }) => {
    return <span>VendorProvider {record ? `"${record.id}"` : ''}</span>;
};

export const VendorProvidersEdit = (props) => (
    <Edit title={<VendorProvidersTitle />} {...props}>
        <SimpleForm>
            <ReferenceInput pagination={<Pagination />} label="Vendor" source="vendorId" reference="vendorsforprovider" filter={{ requireProviderAuthorization: true }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
            <ArrayInput source="providersId" label="Dozvoljeni provajderi">
                <SimpleFormIterator>
                    <ReferenceInput label="Provajderi" reference="providersall">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

export const VendorProvidersCreate = (props) => (
    <Create title="Kreiraj VendorProvider" {...props}>
        <SimpleForm>
            <ReferenceInput label="Vendor" source="vendorId" reference="vendorsforprovider" filter={{ requireProviderAuthorization: true }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
            <ArrayInput source="providersId" label="Dozvoljeni provajderi">
                <SimpleFormIterator>
                    <ReferenceInput label="Provajderi" reference="providersall">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);
