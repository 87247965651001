import React from 'react';
import { List, Datagrid, Edit, Create, SimpleForm, ArrayField, TextField, EditButton, 
    SingleFieldList, TextInput, ChipField, ReferenceArrayInput, UrlField ,SelectArrayInput, 
    ArrayInput, SimpleFormIterator, DateInput, ReferenceInput, SelectInput, usePermissions, ReferenceArrayField} from 'react-admin';
import BookIcon from 'material-ui/svg-icons/action/book';
export const ProvidersIcon = BookIcon;

export const ProvidersList = ({ permissions, ...props }) => (
    <List {...props} title="Lista provajdera (korisnici sistema Placanja)"  permissions={permissions}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="description" />
            <TextField source="contactmail" />
            <TextField source="contactphone" />
            <UrlField source="websiteurl" />
            <TextField source="note" />
            <ArrayField source="users"><SingleFieldList><ChipField source="firstname" /></SingleFieldList></ArrayField>
            <TextField source="applications" />
            <EditButton basePath="/Providers" />
        </Datagrid>
        
    </List>
);

const ProvidersTitle = ({ record }) => {
    return <span>Provider {record ? `"${record.name}"` : ''}</span>;
};

export const ProvidersEdit = ({ permissions, ...props }) => (
    <Edit title={<ProvidersTitle />} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="name" />
            <TextInput source="description"/>
            <TextInput source="contactmail" />
            <TextInput source="contactphone" />
            <TextInput source="websiteurl" />
            <TextInput source="note" />
            <ArrayInput source="users" label="Korisnici">
                <SimpleFormIterator>
                    <TextInput source="firstname" label="Ime"/>
                    <TextInput source="lastname" label="Prezime"/>
                    <DateInput source="birthdate" label="Datum rodjenja"/>
                    <TextInput source="email" label = "e-mail"/>
                    <TextInput source="phone" label="telefon"/>
                    <ReferenceArrayInput label="Polise" reference="policy" source="policies">
                        <SelectArrayInput optionText="name" label="Naziv polise"/>
                    </ReferenceArrayInput>
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="applications" label="Aplikacije">
                <SimpleFormIterator>
                    <TextInput source="name" label="Ime aplikacije"/>
                    <TextInput source="description" label="Opis aplikacije"/>
                    <ArrayInput source="apikeys" label="Api kljucevi">
                        <SimpleFormIterator>
                            <TextInput source="value" label ="Skrivena vednost"/>
                            <ReferenceArrayInput label="Polise" reference="policy" source="policies">
                                <SelectArrayInput optionText="name" label="Naziv polise"/>
                            </ReferenceArrayInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="authorizedvendors" label="Dozvoljeni vendori">
                <SimpleFormIterator>
                    <ReferenceInput label="Vendori" reference="vendorsforprovider" filter={permissions === 'Admin' ? {}:{isHumanitarian:true}} perPage={1000}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
            {permissions === 'Admin' &&
            <ReferenceInput source="countryId" reference="countries" allowEmpty>
                    <SelectInput optionText="name" label="Zemlja"/>
                </ReferenceInput>
                }
        </SimpleForm>
    </Edit>
);

export const ProvidersCreate = ({ permissions, ...props }) => (
    <Create title="Create a Provider" {...props}>
        {permissions === 'Admin' &&
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="description"/>
            <TextInput source="contactmail" />
            <TextInput source="contactphone" />
            <TextInput source="websiteurl" />
            <TextInput source="note" />
            <ArrayInput source="users">
                <SimpleFormIterator>
                    <TextInput source="firstname" />
                    <TextInput source="lastname" />
                    <DateInput source="birthdate" />
                    <TextInput source="email" />
                    <TextInput source="phone" />
                    <ReferenceArrayInput label="Polise" reference="policy" source="policies">
                        <SelectArrayInput optionText="name" label="Naziv polise"/>
                    </ReferenceArrayInput>
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="authorizedvendors" label="Dozvoljeni vendori">
                <SimpleFormIterator>
                    <ReferenceInput label="Vendori" reference="vendorsforprovider" filter={permissions === 'Admin' ? {}:{isHumanitarian:true}} perPage={1000}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
            {permissions === 'Admin' &&
                <ReferenceInput source="countryId" reference="countries" allowEmpty>
                    <SelectInput optionText="name" label="Zemlja"/>
                </ReferenceInput>
            }
        </SimpleForm>
        }
    </Create>
);
