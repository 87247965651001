import React from 'react';
import { Filter, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, SelectArrayInput, DisabledInput, TextInput, LongTextInput, DateInput, BooleanField, BooleanInput, SelectInput } from 'react-admin';
import {PostPagination} from '../PostPagination/PostPagination';
import BookIcon from 'material-ui/svg-icons/action/book';
export const WitForCategoriesIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        
        <TextInput label="id" source="id" ></TextInput>
        <TextInput label="parentId" source="parentId" ></TextInput>
        <TextInput label="Name" source="name" ></TextInput>
        <BooleanInput label="Is Special" source="isSpecial" />
    </Filter>
);

export const WitForCategoryList = (props) => (
    <List {...props} perPage={25} title="Lista Wit key i kategorija" filters={<PostFilter />} >
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="categoryId" />
            <TextField source="witKey" />
            <TextField source="WitToken" />
        </Datagrid>
    </List>
);

const WitForCategoryTitle = ({ record }) => {
    return <span>Wit For Category {record ? `"${record.id}"` : ''}</span>;
};

export const WitForCategoryEdit = (props) => (
    <Edit title={<WitForCategoryTitle />} {...props}>
        <SimpleForm>
            <TextField source="id"/>
            <TextInput source="categoryId" />
            <TextInput source="witKey" />
            <TextInput source="WitToken" />
        </SimpleForm>
    </Edit>
);

export const WitForCategoryCreate = (props) => (
    <Create title="Create a Category" {...props}>
        <SimpleForm>
            <TextInput source="categoryId" />
            <TextInput source="witKey" />
            <TextInput source="WitToken" />
        </SimpleForm>
    </Create>
);
