import React from 'react';
import { Filter, ReferenceInput, SelectInput, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, ReferenceArrayInput, TextInput, SelectArrayInput, ReferenceField, BooleanInput } from 'react-admin';
import {PostPagination} from '../PostPagination/PostPagination';
import BookIcon from 'material-ui/svg-icons/action/book';
export const PolicyClaimIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        
        <TextInput label="firstname" source="firstname" ></TextInput>
        <TextInput label="lastname" source="lastname" ></TextInput>
        <TextInput label="email" source="email" ></TextInput>
        <TextInput label="phone" source="phone" ></TextInput>
    </Filter>
);

export const PolicyClaimList = (props) => (
    <List {...props} perPage={25} title="PolicyClaim" filters={<PostFilter />} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="policyId"/>
            <TextField source="claimId" />
            <EditButton basePath="/PolicyClaim" />
        </Datagrid>
    </List>
);

const PolicyClaimTitle = ({ record }) => {
    return <span>Polisa claim{record ? `"${record.id}"` : ''}</span>;
};

export const PolicyClaimEdit = (props) => (
    <Edit title={<PolicyClaimTitle />} {...props}>
        
        <SimpleForm>
            <ReferenceInput source="policyId" reference="policy" allowEmpty>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="claimId" reference="claim" allowEmpty>
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const PolicyClaimCreate = (props) => (
    <Create title="Kreiraj polisu za claim" {...props}>
        <SimpleForm>
        <ReferenceInput source="policyId" reference="policy" allowEmpty>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput source="claimId" reference="claim" allowEmpty>
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
