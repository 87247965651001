import React from 'react';
import { Filter, ReferenceInput, SelectInput, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, DisabledInput, TextInput, NumberInput, BooleanInput } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import {PostPagination} from '../PostPagination/PostPagination';
import BookIcon from 'material-ui/svg-icons/action/book';
export const AttributeWITMissingIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label="id" source="id" ></TextInput>
        <TextInput label="type" source="type" ></TextInput>
        <TextInput label="Name" source="name" alwaysOn></TextInput>
        <NumberInput label="Source" source="source" ></NumberInput>
    </Filter>
);

export const AttributeWITMissingList = (props) => (
    <List {...props} perPage={25} title="Lista atributa koji je AI otkrio a nema ih" filters={<PostFilter />} >
        <Datagrid>
            <TextField source="type" />
            <TextField source="name" />
            <TextField source="valueContent" />
            <TextField source="source" />
            <TextField source="id" />
            <EditButton basePath="/AttributesWITMissing" />
        </Datagrid>
    </List>
);

const AttributeTitle = ({ record }) => {
    return <span>Attribute {record ? `"${record.id}"` : ''}</span>;
};

const CreateAttributeButton = ({ record }) => (
    <Button
        component={Link}
        to={{
            pathname: '/attributes/create',
            state: { record: { id: record.referenceid, name: record.name, type: record.type } },
        }}
    >
        Kreiraj novi atribut !!
    </Button>
);

export const AttributeWITMissingEdit = (props) => (
    <Edit title={<AttributeTitle />} {...props}>
        <SimpleForm>
            <TextInput source="type" />
            <TextInput source="name" />
            <TextInput source="valueContent" />
            <TextInput source="source" />
            <TextField source="id" />
            <CreateAttributeButton />
        </SimpleForm>
    </Edit>
);

export const AttributeWITMissingCreate = (props) => (
    <Create title="Create Attribute" {...props}>
        <SimpleForm>
            <TextInput source="type" />
            <TextInput source="name" />
            <TextInput source="valueContent" />
            <TextInput source="source" />
            <TextField source="id" />
        </SimpleForm>
    </Create>
);
