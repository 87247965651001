import React from 'react';
import { Filter, ReferenceInput, SelectInput, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, DisabledInput, TextInput, LongTextInput, DateInput, BooleanField, BooleanInput } from 'react-admin';
import {PostPagination} from '../PostPagination/PostPagination';
import BookIcon from 'material-ui/svg-icons/action/book';
export const CategoriesIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        
        <TextInput label="id" source="id" ></TextInput>
        <TextInput label="parentId" source="parentId" ></TextInput>
        <TextInput label="Name" source="name" ></TextInput>
        <BooleanInput label="Is Special" source="isSpecial" />
    </Filter>
);

export const CategoriesList = (props) => (
    <List {...props} perPage={25} title="Lista kategorija" filters={<PostFilter />} >
        <Datagrid>
            <TextField source="id" />
            <TextField source="code" />
            <TextField source="name" />
            <TextField source="description" />
            <TextField source="url" />
            <BooleanField source="isSpecial" />
            <TextField source="keywords" />
            <TextField source="parentId" />
            <EditButton basePath="/Categories" />
        </Datagrid>
    </List>
);

const CategoryTitle = ({ record }) => {
    return <span>Category {record ? `"${record.id}"` : ''}</span>;
};

export const CategoriesEdit = (props) => (
    <Edit title={<CategoryTitle />} {...props}>
        <SimpleForm>
            <TextInput source="id" />
            <TextInput source="code" />
            <TextInput source="name" />
            <TextInput source="description" />
            <TextInput source="url" />
            <TextInput source="imageUrl" />
            <BooleanInput source="isSpecial" />
            <TextInput source="keywords" />
            <TextInput source="parentId" />
        </SimpleForm>
    </Edit>
);

export const CategoriesCreate = (props) => (
    <Create title="Create a Category" {...props}>
        <SimpleForm>
            <TextInput source="code" />
            <TextInput source="name" />
            <TextInput source="description" />
            <TextInput source="url" />
            <TextInput source="imageUrl" />
            <BooleanInput source="isSpecial" />
            <TextInput source="keywords" />
            <TextInput source="parentId" />
        </SimpleForm>
    </Create>
);
