import React from 'react';
import { Filter, SelectInput, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, TextInput, NumberField, 
    DateInput, SimpleFormIterator, BooleanInput, AutocompleteInput, ReferenceInput,
    ArrayField,ArrayInput, ChipField, SingleFieldList, NumberInput, ImageField, UrlField } from 'react-admin';
import BookIcon from 'material-ui/svg-icons/action/book';
import RichTextInput from 'ra-input-rich-text';
export const ManualPaymentsIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label="id" source="id" ></TextInput>
        <TextInput label="Naziv" source="name" alwaysOn></TextInput>
        <TextInput source="paymentAction" label="Status"></TextInput>
        <ReferenceInput label="Vendor" source="vendor" reference="paymentsvendor" alwaysOn allowEmpty="false" format={(v) => (!v ? null : v)} perPage={1000}>
            <SelectInput optionText="name" optionValue="name" />
        </ReferenceInput>
    </Filter>
);

export const ManualPaymentsList = (props) => (
    <List {...props} perPage={25} title="List plaćanja za vendora" filters={<PostFilter />} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" label="Naziv"/>
            <TextField source="category" label="Kategorija"/>
            <UrlField source="detailedUrl" label="URL"/>
            <DateField source="endDate" label="Krajnji datum"/>
            <NumberField source="suggestedAmount" label="Iznos"/>
            <TextField source="paymentAction" label="Status"></TextField>
            <TextField source="uniqueId" label="ID"></TextField>
            <DateField source="modifiedDateTime"></DateField>
            <ArrayField source="attributes" label="Atributi"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>
            <EditButton basePath="/Paymentsmanualmessages" />
        </Datagrid>
    </List>
);

const ManualPaymentsTitle = ({ record }) => {
    return <span>Placanje {record ? `"${record.id}"` : ''}</span>;
};


export const  ManualPaymentsEdit = (props) => (
    <Edit title={<ManualPaymentsTitle />} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="name" label="Naziv"/>
            <TextInput source="category" label="Kategorija" />
            <RichTextInput source="description" label="Opis" />
            <TextInput source="organization" label="Organizacija"/>
            <TextInput source="uniqueId" label="Jedinstven ID"/>
            <TextInput source="detailedUrl" label="URL"/>
            <TextInput source="note" label="Napomena"/>
            <DateInput source="startDate" label="Početni datum"/>
            <DateInput source="endDate" label="Krajnji datum"/>
            <NumberInput source="suggestedAmount" label="Predloženi iznos"/>
            <ArrayInput source="domesticPaymentAttributes.accounts" label="Racuni u domacem transferu">
                    <SimpleFormIterator>
                            <TextInput label="Naziv" />
                    </SimpleFormIterator>
                </ArrayInput>
            <ArrayInput source="domesticPaymentAttributes.paymentAttributes" label="Karakteristike domaceg transfera">
                    <SimpleFormIterator>
                            <TextInput source="name" label="Naziv" />
                            <TextInput source="value" label="Vrednost" />
                    </SimpleFormIterator>
                </ArrayInput>

            <ArrayInput source="foreignPaymentAttributes" label="Karakteristike inostranog transfera">
                <SimpleFormIterator>
                    <TextInput source="beneficiary_name" title="Primaoc Ime" label="Primaoc Ime"/>
                    <TextInput source="beneficiary_address" title="Primaoc Adresa" label="Primaoc Adresa"/>
                    <TextInput source="beneficiary_iban" title="Primaoc IBAN" label="Primaoc IBAN"/>
                    <TextInput source="beneficiary_bank" title="Primaoc banka" label="Primaoc banka"/>
                    <TextInput source="beneficiary_swift" title="Primaoc SWIFT" label="Primaoc SWIFT"/>
                </SimpleFormIterator>
            </ArrayInput>
            <ImageField source="imageUrl" title="Slika" label="Slika"/>
            <TextInput source="imageUrl" title="Slika" label="Slika"/>
            <ArrayInput source="attributes" label="Karakteristike akcije">
                <SimpleFormIterator>
                        <TextInput source="name" label="Naziv" />
                        <TextInput source="value" label="Vrednost" />
                </SimpleFormIterator>
            </ArrayInput>

            <TextInput source="geoLocation.latitude" label="Širina"/>
            <TextInput source="geoLocation.longitude" label="Dužina"/>

            <NumberInput source="paymentGoal.target" label="Cilj skupiti"/>
            <DateInput source="geoLocation.targetDate" label="Do datuma"/>
            <NumberInput source="paymentGoal.percentage" label="Procenat skupljeno"/>
            <NumberInput source="paymentGoal.collected" label="Iznos skupljeno"/>

            <NumberInput source="paymentAction" label="Status"></NumberInput>

            <DateField source="modifiedDateTime"></DateField>
        </SimpleForm>
    </Edit>
);

export const ManualPaymentsCreate = (props) => (
    <Create title="Kreiraj Placanje" {...props}>
        <SimpleForm>
            <TextInput source="name" label="Naziv"/>
            <TextInput source="category" label="Kategorija" />
            <RichTextInput source="description" label="Opis" />
            <TextInput source="organization" label="Organizacija"/>
            <TextInput source="uniqueId" label="Jedinstven ID"/>
            <TextInput source="detailedUrl" label="URL"/>
            <TextInput source="note" label="Napomena"/>
            <DateInput source="startDate" label="Početni datum"/>
            <DateInput source="endDate" label="Krajnji datum"/>
            <NumberInput source="suggestedAmount" label="Predloženi iznos"/>
            <ArrayInput source="domesticPaymentAttributes.accounts" label="Racuni u domacem transferu">
                    <SimpleFormIterator>
                            <TextInput label="Naziv" />
                    </SimpleFormIterator>
                </ArrayInput>
            <ArrayInput source="domesticPaymentAttributes.paymentAttributes" label="Karakteristike domaceg transfera">
                    <SimpleFormIterator>
                            <TextInput source="name" label="Naziv" />
                            <TextInput source="value" label="Vrednost" />
                    </SimpleFormIterator>
                </ArrayInput>

            <ArrayInput source="foreignPaymentAttributes" label="Karakteristike inostranog transfera">
                <SimpleFormIterator>
                    <TextInput source="beneficiary_name" title="Primaoc Ime" label="Primaoc Ime"/>
                    <TextInput source="beneficiary_address" title="Primaoc Adresa" label="Primaoc Adresa"/>
                    <TextInput source="beneficiary_iban" title="Primaoc IBAN" label="Primaoc IBAN"/>
                    <TextInput source="beneficiary_bank" title="Primaoc banka" label="Primaoc banka"/>
                    <TextInput source="beneficiary_swift" title="Primaoc SWIFT" label="Primaoc SWIFT"/>
                </SimpleFormIterator>
            </ArrayInput>
            <ImageField source="imageUrl" title="Slika" label="Slika"/>
            <TextInput source="imageUrl" title="Slika" label="Slika"/>
            <ArrayInput source="attributes" label="Karakteristike akcije">
                <SimpleFormIterator>
                        <TextInput source="name" label="Naziv" />
                        <TextInput source="value" label="Vrednost" />
                </SimpleFormIterator>
            </ArrayInput>

            <TextInput source="geoLocation.latitude" label="Širina"/>
            <TextInput source="geoLocation.longitude" label="Dužina"/>

            <NumberInput source="paymentGoal.target" label="Cilj skupiti"/>
            <DateInput source="geoLocation.targetDate" label="Do datuma"/>
            <NumberInput source="paymentGoal.percentage" label="Procenat skupljeno"/>
            <NumberInput source="paymentGoal.collected" label="Iznos skupljeno"/>
            
            <NumberInput source="paymentAction" label="Status"></NumberInput>

            <DateField source="modifiedDateTime"></DateField>
        </SimpleForm>
    </Create>
);
