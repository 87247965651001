import React from 'react';
import { Filter, ReferenceInput, SelectInput, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, DisabledInput, TextInput, LongTextInput, DateInput, BooleanField, BooleanInput } from 'react-admin';
import { required } from 'react-admin';
import BookIcon from 'material-ui/svg-icons/action/book';
export const UserIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        
        <TextInput label="firstname" source="firstname" ></TextInput>
        <TextInput label="lastname" source="lastname" ></TextInput>
        <TextInput label="email" source="email" ></TextInput>
        <TextInput label="phone" source="phone" ></TextInput>
    </Filter>
);

export const UserList = (props) => (
    <List {...props} perPage={25} title="Korisnik" filters={<PostFilter />} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <TextField source="email" />
            <TextField source="phone" />
            <EditButton basePath="/Credenitial" />
        </Datagrid>
    </List>
);

const UserTitle = ({ record }) => {
    return <span>Korisnik{record ? `"${record.id}"` : ''}</span>;
};

export const UserEdit = (props) => (
    <Edit title={<UserTitle />} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="firstname" validate={[required()]}/>
            <TextInput source="lastname" validate={[required()]}/>
            <TextInput source="email" validate={[required()]} type="email"/>
            <TextInput source="phone" />
        </SimpleForm>
    </Edit>
);

export const UserCreate = ({ permissions, ...props }) => (
    <Create title="Kreiraj korisnika" {...props}>
        <SimpleForm initialValues={{ role: 'admin' }}>

        {permissions.includes('Admin') &&
        <div>
            <TextInput source="firstname" validate={[required()]}/>
            <TextInput source="lastname" validate={[required()]}/>
            <TextInput source="email" validate={[required()]} type="email"/>
            <TextInput source="phone" />
            </div>
}
        </SimpleForm>
    
    </Create>
);

