import React from 'react';
import { Filter, SelectInput, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, ImageField, TextInput, SelectField, 
    DateInput, BooleanField, BooleanInput, RichTextField, ImageInput,
    ArrayField,ReferenceArrayInput, ChipField, SingleFieldList, NumberInput, SelectArrayInput } from 'react-admin';
import BookIcon from 'material-ui/svg-icons/action/book';
import { Statechoices } from '../../../constants/constants';
import RichTextInput from 'ra-input-rich-text';

export const ProductWitIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label="id" source="id" ></TextInput>
        <SelectInput source="state" choices={Statechoices} optionText="name" optionValue="_id" alwaysOn/>
        <TextInput label="Brand" source="product.brand.code" ></TextInput>
        <TextInput label="SKU" source="product.sku" alwaysOn></TextInput>
        <TextInput label="Name" source="product.name" alwaysOn></TextInput>
    </Filter>
);

export const ProductWitList = (props) => (
    <List {...props} perPage={25} title="Lista proizvoda u Que za AI" filters={<PostFilter />} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <SelectField source="state" choices={Statechoices} optionText="name" optionValue="_id" />
            <DateField showTime source="firstAvailability" />
            <TextField source="brand.code" />
            <TextField source="sku" />
            <DateField showTime source="modifiedDateTime" />
            <BooleanField source="processedBYWIT" />
            <ArrayField source="attributes"><SingleFieldList><ChipField source="name" render/></SingleFieldList></ArrayField>
            <ArrayField source="categories"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>
            <EditButton basePath="/ProductStoreIndex" />
        </Datagrid>
    </List>
);

const ProductWitTitle = ({ record }) => {
    return <span>Product {record ? `"${record.id}"` : ''}</span>;
};


export const  ProductWitEdit = (props) => (
    <Edit title={<ProductWitTitle />} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <SelectInput source="state" choices={Statechoices} optionText="name" optionValue="_id" />
            <ArrayField source="vendors"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>
            <TextInput source="name" options={{ multiline: true }} />
            <RichTextInput source="description"  />
            <DateInput source="firstAvailability" />
            <ArrayField source="attributes">
                <Datagrid source="attributes">
                    <TextField source="name" />
                    <TextField source="valueContent"/>
                    <ArrayField source="values">
                        <SingleFieldList>
                            <ChipField source="values" />
                        </SingleFieldList>
                    </ArrayField>
                </Datagrid>
            </ArrayField>
            {/*<ReferenceArrayInput source="attributes" reference="attributes" target="id">
                <SelectArrayInput optionText="name" />
</ReferenceArrayInput>*/}
            <ArrayField source="attributes"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>
            <ArrayField source="categories"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>
            <TextInput source="brand.code" />
            <TextInput source="sku" />
            <TextInput source="url" />
            <ImageField source="productImages[0]" title="Slika" label="Slika"/>
            <TextInput source="productImages" />
            <DateField source="modifiedDateTime" />
            <BooleanInput source="processedBYWIT" />
        </SimpleForm>
    </Edit>
);

export const ProductWitCreate = (props) => (
    <Create title="Create a Product" {...props}>
        <SimpleForm>
            <ArrayField source="vendors"><SingleFieldList><ChipField source="identifier" /></SingleFieldList></ArrayField>
            <TextInput source="name" options={{ multiline: true }} />
            <SelectInput source="state" choices={Statechoices} optionText="name" optionValue="_id" />
            <TextInput source="description" options={{ multiline: true }} />
            <DateInput source="firstAvailability" />
            <ArrayField source="categories"><SingleFieldList><ChipField source="keywords" /></SingleFieldList></ArrayField>
            <TextInput source="brand.code" />
            <TextInput source="sku" />
            <TextInput source="url" />
            <ImageInput source="productImages" />
            <DateField source="modifiedDateTime" />
            <BooleanInput source="processedBYWIT" />
        </SimpleForm>
    </Create>
);
