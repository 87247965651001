import React from 'react';
import { List, Datagrid, Edit, Create, SimpleForm, Filter, TextField, EditButton, ImageField, 
    TextInput, ReferenceInput, BooleanField, BooleanInput,SelectInput, NumberInput } from 'react-admin';
import BookIcon from 'material-ui/svg-icons/action/book';
import RichTextInput from 'ra-input-rich-text';

export const VendorsIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput source="id" />
        <TextInput label="Kod" source="code" ></TextInput>
        <TextInput label="Naziv" source="name" ></TextInput>
        <BooleanInput label="Specijalno" source="isSpecial" ></BooleanInput>
        <BooleanInput label="Humanitarno" source="isHumanitarian" ></BooleanInput>
    </Filter>
);

export const VendorsList = (props) => (
    <List {...props} title="Lista radnji/vendora čiji se proizvodi podržavaju" filters={<PostFilter />} filterDefaultValues={{ isHumanitarian: true }}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="code" label="Kod"/>
            <TextField source="name" label="Naziv"/>
            <TextField source="url" />
            <BooleanField source="isSpecial" />
            <BooleanField source="isHumanitarian" label="Humanitarna"/>
            <BooleanField source="requireProviderAuthorization" label="Autorizacija"/>
            <EditButton basePath="/Vendors" />
        </Datagrid>
    </List>
);

const VendorsTitle = ({ record }) => {
    return <span>Vendor {record ? `"${record.id}"` : ''}</span>;
};

export const VendorsEdit = ({ permissions, ...props }) => (
    <Edit title={<VendorsTitle />} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="code" label="Kod"/>
            <TextInput source="name" multiline label="Naziv"/>
            <RichTextInput source="description" label="Opis"/>
            <TextInput source="url" />
            <ImageField source="imageUrl" title="Slika" label="Slika"/>
            <TextInput source="imageUrl" label="URL"/>
            {permissions === 'Admin' &&
                <BooleanInput source="isSpecial" label="Specijalan?"/>
            }
            {permissions === 'Admin' &&
            <BooleanInput source="isHumanitarian" label="Humanitaran?"/>
            }
            {permissions === 'Admin' &&
                <ReferenceInput source="countryId" reference="countries" allowEmpty>
                    <SelectInput optionText="name" label="Zemlja"/>
                </ReferenceInput>
            }
            <BooleanInput source="requireProviderAuthorization" label="Zahteva se autorizacija?"/>

            <NumberInput source="dataDownloader.protocol" label="Download protocol"/>
            <TextInput source="dataDownloader.settings" label="Podesavanja" multiline/>

            <TextInput source="geoLocation.latitude" label="Širina"/>
            <TextInput source="geoLocation.longitude" label="Dužina"/>
        </SimpleForm>
    </Edit>
);

export const VendorsCreate = ({ permissions, ...props }) => (
    <Create title="Create a Vendor" {...props}>
        <SimpleForm>
            <TextInput source="code" label="Kod"/>
            <TextInput source="name" multiline label="Naziv"/>
            <RichTextInput source="description" label="Opis"/>
            <TextInput source="url" />
            <ImageField source="imageUrl" title="Slika" label="Slika"/>
            <TextInput source="imageUrl" label="URL"/>
            {permissions === 'Admin' &&
                <BooleanInput source="isSpecial" label="Specijalan?"/>
            }
            {permissions === 'Admin' &&
            <BooleanInput source="isHumanitarian" label="Humanitaran?"/>
            }
            {permissions === 'Admin' &&
                <ReferenceInput source="countryId" reference="countries" allowEmpty>
                    <SelectInput optionText="name" label="Zemlja"/>
                </ReferenceInput>
            }
            <BooleanInput source="requireProviderAuthorization" label="Zahteva se autorizacija?"/>

            <NumberInput source="dataDownloader.protocol" label="Download protocol"/>
            <TextInput source="dataDownloader.settings" label="Podesavanja" multiline/>
            
            <TextInput source="geoLocation.latitude" label="Širina"/>
            <TextInput source="geoLocation.longitude" label="Dužina"/>
        </SimpleForm>
    </Create>
);
