import * as React from "react";

import authProvider from './authProvider';

import polyglotI18nProvider from 'ra-i18n-polyglot';

import { Admin, Resource, ListGuesser,EditGuesser } from 'react-admin';
import { fetchUtils } from 'ra-core';
import simpleRestProvider from 'ra-data-simple-rest';
import { ProvidersList, ProvidersEdit, ProvidersCreate, ProvidersIcon } from './components/Providers/Providers';
import { VendorsList, VendorsEdit, VendorsCreate, VendorsIcon } from './components/Vendors/Vendors';
import { CategoriesList, CategoriesEdit, CategoriesCreate, CategoriesIcon } from './components/Categories/Categories';
import { AttributesList, AttributesEdit, AttributesCreate, AttributesIcon } from './components/Attributes/Attributes';
import { AttributeMissingList, AttributeMissingEdit, AttributeMissingCreate, AttributeMissingIcon } from './components/AttributeMissing/AttributeMissing';
import { AttributeWITMissingList, AttributeWITMissingEdit, AttributeWITMissingCreate, AttributeWITMissingIcon } from './components/AttributeWITMissing/AttributeWITMissing';
import { AttributeMappingList, AttributeMappingEdit, AttributeMappingCreate, AttributeMappingIcon } from './components/AttributeMapping/AttributeMapping';
import { CategoryMappingList, CategoryMappingEdit, CategoryMappingCreate, CategoryMappingIcon } from './components/CategoryMapping/CategoryMapping';
import { CategoryMissingList, CategoryMissingEdit, CategoryMissingCreate, CategoryMissingIcon } from './components/CategoryMissing/CategoryMissing';
import { ProductQueList, ProductQueEdit, ProductQueCreate, ProductQueIcon } from './components/Products/ProductQue/ProductQue';
import { ProductWitList, ProductWitEdit, ProductWitCreate, ProductWitIcon } from './components/Products/ProductWit/ProductWit';
import { VendorProvidersList, VendorProvidersEdit, VendorProvidersCreate, VendorProvidersIcon } from './components/VendorProvider/VendorProviders';
import { ProviderOrderVendorsList, ProviderOrderVendorsEdit, ProviderOrderVendorsCreate, ProviderOrderVendorsIcon } from './components/Providers/ProviderOrderVendors';
import { ProvidersVendorsList, ProvidersVendorsEdit, ProvidersVendorsCreate, ProvidersVendorsIcon } from './components/Providers/ProvidersVendors';
import { ProductsElasticList, ProductElasticEdit, ProductElasticCreate, ProductElasticIcon } from './components/Products/ProductsElastic/ProductsElastic';
import { CountriesList, CountriesEdit, CountriesCreate, CountriesIcon } from './components/Countries/Countries';
import { ApiKeyList, ApiKeyEdit, ApiKeyCreate, ApiKeyIcon } from './components/ApiKey/ApiKey';
import { PolicyList, PolicyEdit, PolicyCreate, PolicyIcon } from './components/Policy/Policy';
import { ClaimList, ClaimEdit, ClaimCreate, ClaimIcon } from './components/Claim/Claim';
import { AnalyticsList, AnalyticsShow, AnalyticsIcon } from './components/Analytics/Analytics';
import { WitLogList, WitLogEdit, WitLogIcon } from './components/WitLog/WitLog';
import { ePonudaList, ePonudaEdit, ePonudaIcon } from './components/ProprietaryMessages/ePonuda/ePonuda';
import { ShoppsterList, ShoppsterEdit, ShoppsterIcon } from './components/ProprietaryMessages/Shoppster/Shoppster';
import { FirstPaymentSampleList, FirstPaymentSampleEdit, FirstPaymentSampleCreate,FirstPaymentSampleIcon } from './components/PaymentTemplates/FirstSample/FirstPaymentSample';
import { ManualPaymentsList, ManualPaymentsEdit, ManualPaymentsCreate,ManualPaymentsIcon } from './components/PaymentTemplates/Manual/ManualPayments';
import { FormsList, FormEdit, FormIcon, FormCreate } from './components/Forms/Forms';
import { LastProcessingTimeList, LastProcessingTimeEdit, LastProcessingTimeCreate, LastProcessingTimeIcon } from './components/LastProcessingTime/LastProcessingTime';
import { WitForCategoryList, WitForCategoryEdit, WitForCategoryCreate, WitForCategoryIcon } from './components/WitForCategory/WitForCategory';
import { CredentialList, CredentialEdit, CredentialCreate, CredentialIcon } from './components/Credentials/Credentials';
import { UserList, UserEdit, UserCreate, UserIcon } from './components/Users/Users';
import { UserPolicyList, UserPolicyEdit, UserPolicyCreate, UserPolicyIcon } from './components/UserPolicy/UserPolicy';
import { PolicyClaimList, PolicyClaimEdit, PolicyClaimCreate, PolicyClaimIcon } from './components/PolicyClaim/PolicyClaim';

import logo from './logo.svg';
import Dashboard from './components/Dashboard/Dashboard';
import './App.css';

import englishMessages from './i18n/en';
//import MyLayout from './MyLayout';

const fetchJson = (url, options = {}) => {
     if (!options.headers) {
         options.headers = new Headers({ Accept: 'application/json' });
     }
     // add your own headers here
     const auth = JSON.parse(localStorage.getItem('auth'));
     options.headers.set('Authorization', 'Bearer '+ auth.token);
     return fetchUtils.fetchJson(url, options);
 }


const i18nProvider = polyglotI18nProvider(() => englishMessages, "en", { allowMissing: true, onMissingKey: (key, _, __) => key });
 
const SERVER_URL = process.env.NODE_ENV === 'production' ? window.env.SERVER_URL : window.env.REACT_APP_SERVER_URL;
const dataProvider = simpleRestProvider(SERVER_URL, fetchJson, 'X-Total-Count');
const App = () => (
      <Admin  disableTelemetry dataProvider={dataProvider} title="eWindow Administration" authProvider={authProvider} 
      >
          {permissions => [
          permissions.includes('Admin') ?
          <Resource name="categories" list={CategoriesList} edit={CategoriesEdit} create={CategoriesCreate}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="attributes" list={AttributesList} edit={AttributesEdit} create={AttributesCreate}/>
          : <Resource name="attributes"></Resource>,

          permissions.includes('Admin') ?
          <Resource name="attributesMissing" options={{ label: 'Nedostajući atributi' }} list={AttributeMissingList} edit={AttributeMissingEdit} create={AttributeMissingCreate}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="attributeMapping" options={{ label: 'Mapiranje atributa' }} list={AttributeMappingList} edit={AttributeMappingEdit} create={AttributeMappingCreate}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="categoryMapping" options={{ label: 'Mapiranje kategorija' }} list={CategoryMappingList} edit={CategoryMappingEdit} create={CategoryMappingCreate}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="categoriesSuffix" options={{ label: 'Nedostajuće kategorija' }} list={CategoryMissingList} edit={CategoryMissingEdit} create={CategoryMissingCreate}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="productStoreQue" options={{ label: 'U medjuobradi' }} list={ProductQueList} edit={ProductQueEdit} create={ProductQueCreate}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="productStoreIndex" options={{ label: 'AI obrada' }} list={ProductWitList} edit={ProductWitEdit} create={ProductWitCreate}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="ElasticSearch" options={{ label: 'Elastic' }} list={ProductsElasticList} edit={ProductElasticEdit} create={ProductElasticCreate}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="lastProcessingTime" options={{ label: 'Poslednje vreme obrade' }} list={LastProcessingTimeList} edit={LastProcessingTimeEdit} create={LastProcessingTimeCreate}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="analytics" options={{ label: 'Analitika' }} list={AnalyticsList} show={AnalyticsShow}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="attributesWITMissing" options={{ label: 'Nedostajući WIT atributi' }} list={AttributeWITMissingList} edit={AttributeWITMissingEdit} create={AttributeWITMissingCreate}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="witlogs" options={{ label: 'Wit Log' }} list={WitLogList} edit={WitLogEdit}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="ePonudaZaraMessages" options={{ label: 'ePonuda Zara dolazne' }} list={ePonudaList} edit={ePonudaEdit}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="shoppstermessages" options={{ label: 'Shoppster' }} list={ShoppsterList} edit={ShoppsterEdit}/>
          : null,

          permissions.includes('humanitarno') ||  permissions.includes('operator') || permissions.includes('Admin') ?
          <Resource name="vendors" list={VendorsList} edit={VendorsEdit} create={permissions === 'Admin' ? VendorsCreate : null }/>
          : null,

          permissions.includes('operator') || permissions.includes('Admin') ?
          <Resource name="vendorproviders" options={{ label: 'Ovlašćenja' }} list={VendorProvidersList} edit={VendorProvidersEdit} create={VendorProvidersCreate}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="paymentsfirstmessages" options={{ label: 'Akcije' }} list={FirstPaymentSampleList} edit={FirstPaymentSampleEdit} create={FirstPaymentSampleCreate}/>
          : null,

          permissions.includes('operator') || permissions.includes('Admin') ?
          <Resource name="paymentsmanualmessages" options={{ label: 'Rucne akcije' }} list={ManualPaymentsList} edit={ManualPaymentsEdit} create={ManualPaymentsCreate}/>
          : null,

          permissions.includes('operator') || permissions.includes('Admin') ?
          <Resource name="paymentsvendor" />
          : null,

          permissions.includes('Admin') ?
          <Resource name="witforcategory" list={WitForCategoryList} edit={WitForCategoryEdit} create={WitForCategoryCreate}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="forms" options={{ label: 'Formulari' }} list={FormsList} edit={FormEdit} create={FormCreate}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="providers" options={{ label: 'Provajderi' }}  list={ProvidersList} edit={ProvidersEdit} create={permissions === 'Admin' ? ProvidersCreate : null}/>
          : null,
          
          permissions.includes('banka') || permissions.includes('Admin') ?
          <Resource name="providers" options={{ label: 'Provajderi' }}  list={ProvidersVendorsList} edit={ProvidersVendorsEdit} create={permissions === 'Admin' ? ProvidersCreate : null}/>
          : null,

          permissions.includes('banka') || permissions.includes('Admin') ?
          <Resource name="providersvendororder" options={{ label: 'Sortiranje vendora' }}  list={ProviderOrderVendorsList} edit={ProviderOrderVendorsEdit} create={ProviderOrderVendorsCreate}/>
          : null,


          permissions.includes('Admin') ?
          <Resource name="claim" options={{ label: 'Lista claimova' }} list={ClaimList} edit={ClaimEdit} create={ClaimCreate}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="policy" options={{ label: 'Lista polisa' }} list={PolicyList} edit={PolicyEdit} create={PolicyCreate}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="credentials" options={{ label: 'Kredencijali' }} list={CredentialList} edit={CredentialEdit} create={CredentialCreate}/>
          : null,

          permissions.includes('Admin') ?
          <Resource name="countries" options={{ label: 'Zemlje' }} list={CountriesList} edit={CountriesEdit} create={CountriesCreate}/>
          : null,
          
          permissions.includes('Admin') ?
          <Resource name="users" options={{ label: 'Korisnici' }} list={UserList} edit={UserEdit} create={UserCreate}/>
          : null,

          true ?
          <Resource name="vendorsforprovider"/>
          : null,

          true ?
          <Resource name="providersall"/>
          : null,
           ] }
          
          
     </Admin>
)

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

export default App;
