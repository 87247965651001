import React from 'react';
import { Filter, Show, SimpleShowLayout, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, DisabledInput, TextInput, ReferenceArrayField, BooleanField, 
    BooleanInput, ArrayField, SingleFieldList, ChipField, ArrayInput, ReferenceField, ShowButton } from 'react-admin';
import {PostPagination} from '../PostPagination/PostPagination';
import BookIcon from 'material-ui/svg-icons/action/book';

export const CountriesIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        
        <TextInput label="id" source="id" ></TextInput>
        <TextInput label="Naziv" source="name" ></TextInput>
        <TextInput label="Kod" source="code" ></TextInput>
    </Filter>
);

export const CountriesList = (props) => (
    <List {...props} perPage={25} title="Države" filters={<PostFilter />} >
        <Datagrid>
            <TextField source="id" />
            <TextField source="code" label="Kod"/>
            <TextField source="name" label="Naziv"/>
            <EditButton basePath="/Countries" />
        </Datagrid>
    </List>
);

const CountriesTitle = ({ record }) => {
    return <span>Slog drzava: {record ? `"${record.id}"` : ''}</span>;
};

export const CountriesEdit = (props) => (
    <Edit title={<CountriesTitle />} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="code" label="Kod"/>
            <TextInput source="name" label="Naziv"/>
        </SimpleForm>
    </Edit>
);

export const CountriesCreate = (props) => (
    <Create title={<CountriesTitle />} {...props}>
        <SimpleForm>
            <TextInput source="code" label="Kod"/>
            <TextInput source="name" label="Naziv"/>
        </SimpleForm>
    </Create>
);

