import React from 'react';
import { Filter, Show, SimpleShowLayout, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, DisabledInput, 
    TextInput, LongTextInput, DateInput, BooleanField, 
    BooleanInput, SelectInput, DateTimeInput, ImageField, 
    ArrayField, SelectField, SingleFieldList,
    ChipField, UrlField, RichTextField, NumberField } from 'react-admin';
import BookIcon from 'material-ui/svg-icons/action/book';
import { Statechoices } from '../../../constants/constants';
import RichTextInput from 'ra-input-rich-text';


export const ProductElasticIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Ime" source="name" alwaysOn></TextInput>
        <TextInput label="Opis" source="description" ></TextInput>
        <TextInput label="ean" source="ean" ></TextInput>
    </Filter>
);

export const ProductsElasticList = (props) => (
    <List {...props} perPage={25} title="Lista proizvoda u Que" filters={<PostFilter />} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="product.name" />
            <TextField source="product.ean" />
            <TextField source="product.referenceid" />
            <TextField source="brand.code" />
            <TextField source="sku" />
            <UrlField source="url" />
            <ArrayField source="product.attributes"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>
            <ArrayField source="product.categories"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>

            <EditButton basePath="/ElasticSearch" />
        </Datagrid>
    </List>
);

const ProductElasticTitle = ({ record }) => {
    return <span>Product {record ? `"${record.id}"` : ''}</span>;
};

export const  ProductElasticEdit = (props) => (
    <Edit title={<ProductElasticTitle />} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="product.name" />
            <RichTextInput source="product.description" />
            <TextInput source="product.ean" />
            <TextInput source="product.referenceid" />
            <TextInput source="product.brand.code" />
            <TextInput source="product.sku" />
            <TextInput source="product.url" />
            <ArrayField source="product.attributes"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>
            <ArrayField source="product.categories"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>
            <ImageField source="product.productImages[0]" title="Slika" label="Slika"/>

            <ArrayField source="product.vendors"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>
        </SimpleForm>
    </Edit>
);

export const ProductElasticCreate = (props) => (
    <Create title="Create a Product" {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="product.name" />
            <RichTextInput source="product.description" />
            <TextInput source="product.ean" />
            <TextInput source="product.referenceid" />
            <TextInput source="product.brand.code" />
            <TextInput source="product.sku" />
            <TextInput source="product.url" />
            <ArrayField source="product.attributes"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>
            <ArrayField source="product.categories"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>
            <ImageField source="product.productImages[0]" title="Slika" label="Slika"/>

            <ArrayField source="product.vendors"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>
        </SimpleForm>
    </Create>
);
