import React from 'react';
import { Filter, ReferenceInput, SelectInput, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, DisabledInput, TextInput, LongTextInput, DateInput, ReferenceField, BooleanInput, DateTimeInput } from 'react-admin';
import {PostPagination} from '../PostPagination/PostPagination';
import BookIcon from 'material-ui/svg-icons/action/book';
export const AttributesIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        
        <TextInput label="id" source="id" ></TextInput>
        <TextInput label="parentId" source="parentId" ></TextInput>
        <TextInput label="Name" source="name" ></TextInput>
        <BooleanInput label="Is Special" source="isSpecial" />
    </Filter>
);

export const LastProcessingTimeList = (props) => (
    <List {...props} perPage={25} title="Vremena obrade (ne gleda se u toku obrade)" filters={<PostFilter />} >
        <Datagrid rowClick="edit">
            <DateField showTime source="lastProcessingTime" />
            <DateField showTime source="startedProcessingTime" />
            <TextField source="lastId"/>
            <TextField source="forType" />
            <TextField source="id" />
        </Datagrid>
    </List>
);

const LastProcessingTimeTitle = ({ record }) => {
    return <span>Attribute  Maping{record ? `"${record.id}"` : ''}</span>;
};

export const LastProcessingTimeEdit = (props) => (
    <Edit title={<LastProcessingTimeTitle />} {...props}>
        <SimpleForm>
            <DateTimeInput showTime source="startedProcessingTime" />
            <DateTimeInput showTime source="lastProcessingTime" />
            <TextInput source="lastId" ></TextInput>
            <TextInput source="forType" />
            <TextField source="id" />
        </SimpleForm>
    </Edit>
);

export const LastProcessingTimeCreate = (props) => (
    <Create title="Create a Attribute Mapping" {...props}>
        <SimpleForm>
            <TextInput source="lastId" ></TextInput>
            <TextInput source="forType" />
            <TextField source="id" />
        </SimpleForm>
    </Create>
);
