import React from 'react';
import { Filter, ReferenceInput, SelectInput, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, DisabledInput, TextInput, PasswordInput, DateInput, BooleanField, BooleanInput } from 'react-admin';
import {PostPagination} from '../PostPagination/PostPagination';
import BookIcon from 'material-ui/svg-icons/action/book';
export const CredentialIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        
        <TextInput label="userId" source="userId" ></TextInput>
        <TextInput label="username" source="username" ></TextInput>
    </Filter>
);

export const CredentialList = (props) => (
    <List {...props} perPage={25} title="Kredencijali" filters={<PostFilter />} >
        <Datagrid rowClick="edit">
            <TextField source="userId" />
            <TextField source="username" />
            <BooleanField source="isActive" />
            <EditButton basePath="/Credentials" />
        </Datagrid>
    </List>
);

const CredentialTitle = ({ record }) => {
    return <span>Attribute  Maping{record ? `"${record.id}"` : ''}</span>;
};

export const CredentialEdit = (props) => (
    <Edit title={<CredentialTitle />} {...props}>
        <SimpleForm>
            <ReferenceInput source="userId" reference="users" allowEmpty>
                <SelectInput optionText="firstname" />
            </ReferenceInput>
            <TextInput source="username" />
            <PasswordInput source="password" />
            <BooleanInput source="isActive" />
        </SimpleForm>
    </Edit>
);

export const CredentialCreate = (props) => (
    <Create title="Kreiranje kredencijala" {...props}>
        <SimpleForm>
            <ReferenceInput source="userId" reference="users" allowEmpty>
                <SelectInput optionText="firstname" />
            </ReferenceInput>
            <TextInput source="username" />
            <PasswordInput source="password" />
            <BooleanInput source="isActive" />
        </SimpleForm>
    </Create>
);
