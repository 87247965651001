import React from 'react';
import { Filter, ReferenceInput, ReferenceManyField, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, DisabledInput, TextInput, ReferenceArrayField, BooleanField, 
    BooleanInput, ArrayField, SingleFieldList, ChipField, ArrayInput, ReferenceField } from 'react-admin';
import {PostPagination} from '../PostPagination/PostPagination';
import BookIcon from 'material-ui/svg-icons/action/book';
import { Typography, Box, Toolbar } from '@material-ui/core';

export const WitLogIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        
        <TextInput label="id" source="id" ></TextInput>
            <TextInput label="Input text" source="Input" alwaysOn/>
        <TextInput label="Product" source="source_id"/>
    </Filter>
);

export const WitLogList = (props) => (
    <List {...props} perPage={25} title="Mapiranje atributa" filters={<PostFilter />} >
        <Datagrid >
            <TextField source="id" />
            <TextField source="input" />
            <TextField source="source_id" />
            <ArrayField source="ai_Data.entities" label="AI"><SingleFieldList><PurpleTextField source="name" source2="confidence" selectedvalue="value" /></SingleFieldList></ArrayField>
            <EditButton basePath="/WitLogs" />
        </Datagrid>
    </List>
);

const WitLogTitle = ({ record }) => {
    return <span>Attribute  Maping{record ? `"${record.id}"` : ''}</span>;
};

const PurpleTextField = ({ record, source, source2, selectedvalue }) => {

    let color = { color: 'red' };
    if(record[source2]>0.8)
    {
        color = { color: 'blue' };
    }
    return <>
    {
        <span style={color}>{record[source]}: [{record[selectedvalue]}]- {record[source2]}</span>||<ul/>
    }</>
};

const Aside = ({record, props}) => (
    <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="h6">AI rezultati</Typography>
        <Typography variant="body2">
            Ovde možeš da vidiš rezultat AI (polje: Ai data.entities) za prosleđeni tekst (polje: Ai data.text) !!
        </Typography>
        <br/>
        <Typography variant="body2">
            "Sirova" AI povratna struktura se vidi u polju: Content !!
        </Typography>
    </div>
);

export const WitLogEdit = (props) => (
    <Edit aside={<Aside />} title={<WitLogTitle />} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <ReferenceField label="Product" reference="productStoreIndex" source="source_id" >
                <ChipField source="name" />
            </ReferenceField>
            <TextField source="input" />
            <ReferenceField label="Product" reference="productStoreIndex" source="source_id" >
                <ChipField source="name" />
            </ReferenceField>
            <TextField source="content" />
            <TextField source="ai_Data.text" />
            <ArrayField source="ai_Data.entities"><SingleFieldList><PurpleTextField source="name" source2="confidence" selectedvalue="value"/></SingleFieldList></ArrayField>
        </SimpleForm>
    </Edit>
);

