import React from 'react';
import { List, Datagrid, Edit, Create, SimpleForm, ArrayField, TextField, EditButton, 
    SingleFieldList, TextInput, ChipField, ReferenceField, ReferenceArrayField ,SelectArrayInput, 
    ArrayInput, SimpleFormIterator, DateInput, ReferenceInput, SelectInput, Filter, Pagination, NumberField, NumberInput} from 'react-admin';
import BookIcon from 'material-ui/svg-icons/action/book';
export const ProviderOrderVendorsVendorsIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        
        <TextInput label="Vendor" source="vendorid" ></TextInput>
        <TextInput label="Provider" source="providerid" ></TextInput>
    </Filter>
);

export const ProviderOrderVendorsList = ({ permissions, ...props }) => (

    <List {...props} title="Autorizacija vendora i provajdera" permissions={permissions} filters={<PostFilter />}>
        <Datagrid rowClick="edit">
            <ReferenceField label="Provajder" source="providerReferenceId" reference="providers" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <EditButton basePath="/ProvidersVendorOrder" />
        </Datagrid>
    </List>
);

const ProviderOrderVendorsTitle = ({ record }) => {
    return <span>Provider {record ? `"${record.name}"` : ''}</span>;
};

export const ProviderOrderVendorsEdit = (props) => (
    <Edit title={<ProviderOrderVendorsTitle />} {...props}>
        <SimpleForm>
            <ReferenceInput pagination={<Pagination />} label="Provajderi" source="providerReferenceId" reference="providers" filter={{ isHumanitarian: true }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
            <ArrayInput source="vendorsOrder" label="Dozvoljeni vendori">
                <SimpleFormIterator>
                    <ReferenceInput source="vendorId" label="Vendori" reference="vendors">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <NumberInput source="position" label="Pozicija"/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

export const ProviderOrderVendorsCreate = ({ permissions, ...props }) => (
    <Create title={<ProviderOrderVendorsTitle />} {...props}>
        <SimpleForm>
            <ReferenceInput pagination={<Pagination />} label="Provajderi" source="providerReferenceId" reference="providers" filter={{ isHumanitarian: true }}>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
            <ArrayInput source="vendorsOrder" label="Dozvoljeni vendori">
                <SimpleFormIterator>
                    <ReferenceInput source="vendorId" label="Vendori" reference="vendorsforprovider">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <NumberInput source="position" label="Pozicija"/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
    
);
