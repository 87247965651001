
// export default {
//     login: ({ username, password }) =>  {
//         const SERVER_URL = process.env.NODE_ENV === 'production' ? window.env.SERVER_URL : window.env.REACT_APP_SERVER_URL;
//         const Login_URL = SERVER_URL + '/Login';
//         const request = new Request(Login_URL, {
//             method: 'POST',
//             body: JSON.stringify({ username, password }),
//             headers: new Headers({ 'Content-Type': 'application/json' }),
//         });
//         return fetch(request)
//             .then(response => {
//                 if (response.status < 200 || response.status >= 300) {
//                     throw new Error(response.statusText);
//                 }

//                 //return Promise.resolve();
//                 return response.json();
//             })
//             .then(auth => {
//                 localStorage.setItem('auth', JSON.stringify(auth));
//             })
//             .catch((error) => {
//                 throw new Error('Network error')
//             });
//     },
//    logout: () => {
//         localStorage.removeItem('username');
//         return Promise.resolve();
//     },
//     checkError: ({ status }) => {
//         if (status === 401 || status === 403) {
//             localStorage.removeItem('username');
//             return Promise.reject();
//         }
//         return Promise.resolve();
//     },
//     checkAuth: () => {
//         return localStorage.getItem('username')
//             ? Promise.resolve()
//             : Promise.reject();
//     },
//     getPermissions: () => Promise.resolve(),
//  };
import decodeJwt from 'jwt-decode';

const authProvider = {
    login: ({ username, password }) =>  {
        const SERVER_URL = process.env.NODE_ENV === 'production' ? window.env.SERVER_URL : window.env.REACT_APP_SERVER_URL;
        const Login_URL = SERVER_URL + '/Login';
        const request = new Request(Login_URL, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then(auth => {
                //localStorage.setItem('username', username);
                const decodedToken = decodeJwt(auth.token);
                localStorage.setItem('permissions', decodedToken.role);
                localStorage.setItem('policy', decodedToken.policy);
                localStorage.setItem('auth', JSON.stringify(auth));
            })
            .catch((error) => {
                throw new Error('Network error')
            });
    },
   logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        /*if(status==undefined)
        {
            localStorage.removeItem('auth');
            return Promise.reject();
        }*/
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('auth')
            ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    },
    getIdentity: () => {
        try {
            const { id, fullName, avatar } = JSON.parse(localStorage.getItem('auth'));
            return Promise.resolve({ id, fullName, avatar });
        } catch (error) {
            return Promise.reject(error);
        }
    },
 };

 export default authProvider;