import React from 'react';
import { Filter, Show, SimpleShowLayout, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, DisabledInput, TextInput, ReferenceArrayField, BooleanField, 
    BooleanInput, ArrayField, SingleFieldList, ChipField, ArrayInput, ReferenceField, ShowButton } from 'react-admin';
import {PostPagination} from '../PostPagination/PostPagination';
import BookIcon from 'material-ui/svg-icons/action/book';

export const AnalyticsIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        
        <TextInput label="id" source="id" ></TextInput>
        <TextInput label="Token" source="Header.Token" ></TextInput>
    </Filter>
);

export const AnalyticsList = (props) => (
    <List {...props} perPage={25} title="Analitika" filters={<PostFilter />} >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="header.token" />
            <TextField source="source.ipAddress" />
            <DateField source="time" showTime/>
            <ShowButton basePath="/Analytics" />
        </Datagrid>
    </List>
);

const AnalyticsTitle = ({ record }) => {
    return <span>Slog analitike: {record ? `"${record.id}"` : ''}</span>;
};

const IPUrl = ({ record }) => {
    return <a href={"https://www.iplocationtools.com/"+record.source.ipAddress}>Detalji</a>;
};

export const AnalyticsShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
        <TextField source="id" />
            <TextField source="header.token" />
            <TextField source="source.ipAddress" />
            {<IPUrl />}
            <TextField source="source.path" />
            <TextField source="source.method" />
            <DateField source="time" showTime/>
        </SimpleShowLayout>
    </Show>
);

