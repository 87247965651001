import React from 'react';
import { Filter, ReferenceInput, SelectInput, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, DisabledInput, TextInput, LongTextInput, DateInput, BooleanField, BooleanInput } from 'react-admin';
import {PostPagination} from '../PostPagination/PostPagination';
import BookIcon from 'material-ui/svg-icons/action/book';
export const AttributesIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        
        <TextInput label="id" source="id" ></TextInput>
        <TextInput label="parentId" source="parentId" ></TextInput>
        <TextInput label="Name" source="name" ></TextInput>
        <BooleanInput label="Is Special" source="isSpecial" />
    </Filter>
);

export const AttributeMappingList = (props) => (
    <List {...props} perPage={25} title="Mapiranje atributa" filters={<PostFilter />} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="vendorName" />
            <TextField source="attributeSource.name" />
            <TextField source="attributeDestination.id" />
            <TextField source="attributeDestination.name" />
            <EditButton basePath="/AttributeMapping" />
        </Datagrid>
    </List>
);

const AttributeMappingTitle = ({ record }) => {
    return <span>Attribute  Maping{record ? `"${record.id}"` : ''}</span>;
};

export const AttributeMappingEdit = (props) => (
    <Edit title={<AttributeMappingTitle />} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="vendorName" />
            <TextInput source="attributeSource.name" />
            <TextInput source="attributeDestination.id" />
            <TextInput source="attributeDestination.name" />
        </SimpleForm>
    </Edit>
);

export const AttributeMappingCreate = (props) => (
    <Create title="Create a Attribute Mapping" {...props}>
        <SimpleForm>
            <TextInput source="vendorName" />
            <TextInput source="attributeSource.name" />
            <TextInput source="attributeDestination.id" />
            <TextInput source="attributeDestination.name" />
        </SimpleForm>
    </Create>
);
