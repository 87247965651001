import React from 'react';
import { Filter, ReferenceInput, SelectInput, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, NumberInput, TextInput, LongTextInput, DateInput, BooleanField, BooleanInput } from 'react-admin';
import {PostPagination} from '../PostPagination/PostPagination';
import BookIcon from 'material-ui/svg-icons/action/book';
export const AttributeMissingIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        
        <TextInput label="id" source="id" ></TextInput>
        <TextInput label="type" source="type" ></TextInput>
        <TextInput label="parentId" source="parentId" ></TextInput>
        <TextInput label="Name" source="name" alwaysOn></TextInput>
        <NumberInput label="priority" source="priority" ></NumberInput>
    </Filter>
);

export const AttributeMissingList = (props) => (
    <List {...props} perPage={25} title="Lista atributa koji nedotaju o bazičnoj obradi" filters={<PostFilter />} >
        <Datagrid>
            <TextField source="type" />
            <TextField source="name" />
            <TextField source="valueContent" />
            <TextField source="source" />
            <TextField source="id" />
            <EditButton basePath="/AttributesMissing" />
        </Datagrid>
    </List>
);

const AttributeTitle = ({ record }) => {
    return <span>Attribute {record ? `"${record.id}"` : ''}</span>;
};

export const AttributeMissingEdit = (props) => (
    <Edit title={<AttributeTitle />} {...props}>
        <SimpleForm>
            <TextInput source="type" />
            <TextInput source="name" />
            <TextInput source="valueContent" />
            <TextInput source="source" />
            <TextField source="id" />
        </SimpleForm>
    </Edit>
);

export const AttributeMissingCreate = (props) => (
    <Create title="Create a Category" {...props}>
        <SimpleForm>
            <TextInput source="type" />
            <TextInput source="name" />
            <TextInput source="valueContent" />
            <TextInput source="source" />
        </SimpleForm>
    </Create>
);
