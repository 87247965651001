import React from 'react';
import { Filter, ReferenceInput, SelectInput, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, ReferenceArrayInput, TextInput, SelectArrayInput, ReferenceField, ArrayField, BooleanInput } from 'react-admin';
import {PostPagination} from '../PostPagination/PostPagination';
import BookIcon from 'material-ui/svg-icons/action/book';
export const UserPolicyIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        
        <TextInput label="firstname" source="firstname" ></TextInput>
        <TextInput label="lastname" source="lastname" ></TextInput>
        <TextInput label="email" source="email" ></TextInput>
        <TextInput label="phone" source="phone" ></TextInput>
    </Filter>
);

export const UserPolicyList = (props) => (
    <List {...props} perPage={25} title="Korisnik" filters={<PostFilter />} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="userId"/>
            <TextField source="policy" />
            <EditButton basePath="/userpolicy" />
        </Datagrid>
    </List>
);

const UserPolicyTitle = ({ record }) => {
    return <span>Polisa korisnika{record ? `"${record.id}"` : ''}</span>;
};

export const UserPolicyEdit = (props) => (
    <Edit title={<UserPolicyTitle />} {...props}>
        
        <SimpleForm>
            <ReferenceInput source="userId" reference="users"><SelectInput optionText="firstname" /></ReferenceInput>
            <TextField source="userId"/>
            <ReferenceInput source="policy" reference="policy" allowEmpty>
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export const UserPolicyCreate = (props) => (
    <Create title="Kreiraj polisu za korisnika" {...props}>
        <SimpleForm>
        <ReferenceInput source="userId" reference="users"><SelectInput optionText="firstname" /></ReferenceInput>
            <ReferenceInput source="policy" reference="policy" allowEmpty>
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
