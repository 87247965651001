import React from 'react';
import { Filter, List, Datagrid, Edit, Create, SimpleForm, TextField, EditButton, 
    TextInput, BooleanInput, ReferenceArrayInput, SelectArrayInput, ChipField } from 'react-admin';
import {PostPagination} from '../PostPagination/PostPagination';
import BookIcon from 'material-ui/svg-icons/action/book';
export const PolicyIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        
        <TextInput label="id" source="id" ></TextInput>
        <TextInput label="parentId" source="parentId" ></TextInput>
        <TextInput label="Name" source="name" ></TextInput>
        <BooleanInput label="Is Special" source="isSpecial" />
    </Filter>
);

export const PolicyList = (props) => (
    <List {...props} perPage='25' title="Definisanje polisa" filters={<PostFilter />} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="description" />
            <EditButton basePath="/Policy" />
        </Datagrid>
    </List>
);

const PolicyTitle = ({ record }) => {
    return <span>Polisa:{record ? `"${record.name}"` : ''}</span>;
};

export const PolicyEdit = (props) => (
    <Edit title={<PolicyTitle />} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <TextInput source="name" />
            <TextInput source="description" />
            <ReferenceArrayInput label="Clejmovi" reference="claim" source="claims">
                <SelectArrayInput optionText="name" label="Naziv"/>
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);

export const PolicyCreate = (props) => (
    <Create title="Create a Attribute Mapping" {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="description" />
            <ReferenceArrayInput label="Clejmovi" reference="claim" source="claims">
                <SelectArrayInput optionText="name" label="Naziv"/>
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);
