import React from 'react';
import { Filter, SelectInput, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, TextInput, NumberField, 
    DateInput, BooleanField, BooleanInput, RichTextField, ImageInput,
    ArrayField,ReferenceArrayInput, ChipField, SingleFieldList, NumberInput, ImageField, UrlField } from 'react-admin';
import BookIcon from 'material-ui/svg-icons/action/book';
import { Statechoices } from '../../../constants/constants';

export const ePonudaIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label="id" source="id" ></TextInput>
        <TextInput label="Naziv" source="naziv_proizvoda" alwaysOn></TextInput>
        <TextInput label="SKU" source="sku" alwaysOn></TextInput>
        <NumberInput label="Nov" source="nov_proizvod"></NumberInput>
    </Filter>
);

export const ePonudaList = (props) => (
    <List {...props} perPage={25} title="Lista Zara" filters={<PostFilter />} >
        <Datagrid rowClick="edit">
            <ArrayField source="karakteristike">
                <SingleFieldList><ChipField source="ime" /></SingleFieldList>
            </ArrayField>
            <TextField source="sku" />
            <TextField source="kategorija_proizvoda" />
            <TextField source="naziv_proizvoda" />
            <TextField source="model" />
            <UrlField source="proizvod_url" />
            <NumberField source="cena" />
            <NumberField source="nov_proizvod" />
            <ImageField source="slika_url" title="Slika" label="Slika"/>
            <NumberField source="ean_upc" />
            <TextField source="id" />
            <EditButton basePath="/ePonudaZaraMessages" />
        </Datagrid>
    </List>
);

const ePonudaTitle = ({ record }) => {
    return <span>Product {record ? `"${record.id}"` : ''}</span>;
};


export const  ePonudaEdit = (props) => (
    <Edit title={<ePonudaTitle />} {...props}>
        <SimpleForm>
            <ArrayField source="karakteristike">
                <SingleFieldList><ChipField source="ime" /></SingleFieldList>
            </ArrayField>
            <TextInput source="sku" />
            <TextInput source="kategorija_proizvoda" />
            <TextInput source="naziv_proizvoda" />
            <TextInput source="proizvodjac" />
            <TextInput source="model" />
            <TextInput source="specifikacija" />
            <TextInput source="proizvod_url" />
            <NumberField source="cena" />
            <NumberField source="proizvod_na_akciji" />
            <NumberField source="nov_proizvod" />
            <ImageField source="slika_url" title="Slika" label="Slika"/>
            <TextInput source="slika_url" />
            <NumberField source="ean_upc" />
            <TextInput source="mpn" />
            <TextInput source="napomena" />
            <TextInput source="garancija" />
            <NumberField source="dostupnost_proizvoda" />
            <TextInput source="troskovi_isporuke" />
            <TextField source="reference_id" />
        </SimpleForm>
    </Edit>
);

export const ProductWitCreate = (props) => (
    <Create title="Create a Product" {...props}>
        <SimpleForm>
            <ArrayField source="karakteristike">
                <SingleFieldList><ChipField source="ime" /></SingleFieldList>
            </ArrayField>
            <TextInput source="sku" />
            <TextInput source="kategorija_proizvoda" />
            <TextInput source="naziv_proizvoda" />
            <TextInput source="proizvodjac" />
            <TextInput source="model" />
            <TextInput source="specifikacija" />
            <TextInput source="proizvod_url" />
            <NumberField source="cena" />
            <NumberField source="proizvod_na_akciji" />
            <NumberField source="nov_proizvod" />
            <TextInput source="slika_url" />
            <NumberField source="ean_upc" />
            <TextInput source="mpn" />
            <TextInput source="napomena" />
            <TextInput source="garancija" />
            <NumberField source="dostupnost_proizvoda" />
            <TextInput source="troskovi_isporuke" />
            <TextInput source="reference_id" />
        </SimpleForm>
    </Create>
);
