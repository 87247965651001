import React from 'react';
import { Filter, Show, SimpleShowLayout, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, DisabledInput, 
    TextInput, LongTextInput, DateInput, BooleanField, 
    BooleanInput, SelectInput, DateTimeInput, ImageField, 
    ArrayField, SelectField, SingleFieldList,
    ChipField, UrlField, RichTextField } from 'react-admin';
import BookIcon from 'material-ui/svg-icons/action/book';
import { Statechoices } from '../../../constants/constants';
import RichTextInput from 'ra-input-rich-text';


export const ProductQueIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" ></TextInput>
        <TextInput label="id" source="id" ></TextInput>

        <TextInput label="Vendor" source="vendor.code" ></TextInput>
        <TextInput label="SKU" source="sku" alwaysOn></TextInput>
        <SelectInput source="state" choices={Statechoices} optionText="name" optionValue="_id" alwaysOn/>
        <TextInput label="ean" source="ean" ></TextInput>
        <TextInput label="Reference" source="referenceRecord" ></TextInput>
    </Filter>
);

export const ProductQueList = (props) => (
    <List {...props} perPage={25} title="Lista proizvoda u Que" filters={<PostFilter />} >
        <Datagrid rowClick="edit">
            <TextField source="vendor.code" />
            <TextField source="price.formattedValue" />
            <SelectField source="state" choices={Statechoices} optionText="name" optionValue="_id" />
            <TextField source="referenceRecord" />
            <TextField source="name" />
            <TextField source="brand.code" />
            <TextField source="sku" />
            <UrlField source="url" />
            <TextField source="ean" />
            <TextField source="productAction" />
            <DateField showTime source="modifiedDateTime" />
            <ArrayField source="attributes"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>
            <ArrayField source="categories"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>
            <EditButton basePath="/ProductStoreQue" />
        </Datagrid>
    </List>
);

const ProductQueTitle = ({ record }) => {
    return <span>Product {record ? `"${record.id}"` : ''}</span>;
};

export const  ProductQueEdit = (props) => (
    <Edit title={<ProductQueTitle />} {...props}>
        <SimpleForm>
            <TextField source="id"/>
            <SelectInput source="state" choices={Statechoices} optionText="name" optionValue="_id" />
            <TextInput source="price.currencyIso" />
            <TextInput source="price.value" />
            <TextInput source="referenceRecord" />
            <ArrayField source="attributes"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>
            <ArrayField source="categories"><SingleFieldList><ChipField source="name" /></SingleFieldList></ArrayField>
            <TextInput source="processedBYWIT" />
            <TextInput source="guaranty" />
            <TextInput source="deliveryNote" />
            <TextInput source="note" options={{ multiline: true }} />
            <TextInput source="name" options={{ multiline: true }} />
            <TextInput source="regulatedName" />
            <RichTextInput source="description" />
            <TextInput source="countryOfOrigin" />
            <TextInput source="safetyInformation" />
            <TextInput source="storageInstructions" />
            <DateTimeInput source="firstAvailability" />
            <TextInput source="brand.code" />
            <TextInput source="sku" />
            <TextInput source="url" />
            <ImageField source="productImages[0]" title="Slika" label="Slika"/>
            <TextInput source="ean" />
            <TextInput source="model" />
            <DateTimeInput source="modifiedDateTime" />
        </SimpleForm>
    </Edit>
);

export const ProductQueCreate = (props) => (
    <Create title="Create a Product" {...props}>
        <SimpleForm>
            <SelectInput source="state" choices={Statechoices} optionText="name" optionValue="_id" />
            <TextInput source="price.currencyIso" />
            <TextInput source="referenceRecord" />
            <TextInput source="processedBYWIT" />
            <TextInput source="guaranty" />
            <TextInput source="deliveryNote" />
            <TextInput source="note" />
            <TextInput source="name" options={{ multiline: true }} />
            <RichTextInput source="description" />
            <TextInput source="countryOfOrigin" />
            <TextInput source="safetyInformation" />
            <TextInput source="storageInstructions" />
            <DateTimeInput source="firstAvailability" />
            <TextInput source="brand.code" />
            <TextInput source="sku" />
            <TextInput source="url" />
            <TextInput source="productImages" />
            <TextInput source="ean" />
            <TextInput source="model" />
            <DateTimeInput source="modifiedDateTime" />
        </SimpleForm>
    </Create>
);
