import React from 'react';
import { Filter, SelectInput, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, TextInput, NumberField, 
    DateInput, BooleanField, BooleanInput, RichTextField, ImageInput,
    ArrayField,ReferenceArrayInput, ChipField, SingleFieldList, NumberInput, ImageField, UrlField } from 'react-admin';
import BookIcon from 'material-ui/svg-icons/action/book';
import { Statechoices } from '../../../constants/constants';

export const ShoppsterIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        <TextInput label="id" source="id" ></TextInput>
        <TextInput label="Naziv" source="name" alwaysOn></TextInput>
        <TextInput label="SKU" source="vendorSku" alwaysOn></TextInput>
        <TextInput label="Code" source="code" alwaysOn></TextInput>
    </Filter>
);

export const ShoppsterList = (props) => (
    <List {...props} perPage={25} title="Lista Shoppster proizvoda" filters={<PostFilter />} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <DateField source="baseProductData.code" />
            <ArrayField source="categories"><SingleFieldList><ChipField source="code" /></SingleFieldList></ArrayField>
            <ArrayField source="classifications"><SingleFieldList><ChipField source="code" /></SingleFieldList></ArrayField>
            <TextField source="code" />
            <TextField source="name" />
            <UrlField source="url" />
            <TextField source="vendorSku" />
            <EditButton basePath="/shoppstermessages" />
        </Datagrid>
    </List>
);

const ShoppsterTitle = ({ record }) => {
    return <span>Product {record ? `"${record.id}"` : ''}</span>;
};


export const  ShoppsterEdit = (props) => (
    <Edit title={<ShoppsterTitle />} {...props}>
        <SimpleForm>
            <TextField source="id" />
            <DateField source="modifiedDateTime" />
            <BooleanField source="availableForPickup" />
            <DateField source="baseProduct" />
            <DateField source="baseProductData.code" />
            <TextInput source="brandCategory.code" />
            <ArrayField source="categories"><SingleFieldList><ChipField source="code" /></SingleFieldList></ArrayField>
            <ArrayField source="classifications"><SingleFieldList><ChipField source="code" /></SingleFieldList></ArrayField>
            <DateField source="code" />
            <BooleanField source="configurable" />
            <RichTextField source="description" />
            <TextInput source="description" />
            <TextInput source="energyEfficiency" />
            <BooleanField source="freeDelivery" />
            <BooleanField source="gift" />
            <ArrayField source="images"><SingleFieldList><ChipField source="format" /></SingleFieldList></ArrayField>
            <BooleanField source="multidimensional" />
            <TextField source="name" />
            <BooleanField source="newProductSticker" />
            <NumberField source="numberOfReviews" />
            <NumberField source="otherVendorsNumber" />
            <TextField source="potentialPromotions" />
            <TextInput source="price.currencyIso" />
            <TextField source="price.formattedValue" />
            <TextInput source="price.value" />
            <TextInput source="price.priceType" />
            <TextInput source="priceRange.maxPrice.currencyIso" />
            <TextField source="productReferences" />
            <BooleanField source="purchasable" />
            <TextField source="reviews" />
            <BooleanField source="saleable" />
            <NumberField source="stock.stockLevel" />
            <TextField source="summary" />
            <UrlField source="url" />
            <TextField source="variantCategories" />
            <TextField source="variantMatrix" />
            <DateField source="vendor.code" />
            <TextField source="vendorSku" />
        </SimpleForm>
    </Edit>
);

export const ShoppsterCreate = (props) => (
    <Create title="Create a Product" {...props}>
        <SimpleForm>
            <ArrayField source="karakteristike">
                <SingleFieldList><ChipField source="ime" /></SingleFieldList>
            </ArrayField>
            <TextInput source="sku" />
            <TextInput source="kategorija_proizvoda" />
            <TextInput source="naziv_proizvoda" />
            <TextInput source="proizvodjac" />
            <TextInput source="model" />
            <TextInput source="specifikacija" />
            <TextInput source="proizvod_url" />
            <NumberField source="cena" />
            <NumberField source="proizvod_na_akciji" />
            <NumberField source="nov_proizvod" />
            <TextInput source="slika_url" />
            <NumberField source="ean_upc" />
            <TextInput source="mpn" />
            <TextInput source="napomena" />
            <TextInput source="garancija" />
            <NumberField source="dostupnost_proizvoda" />
            <TextInput source="troskovi_isporuke" />
            <TextInput source="reference_id" />
        </SimpleForm>
    </Create>
);
