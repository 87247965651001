import React from 'react';
import { Filter, EmailField, SelectInput, List, Datagrid, Edit, Create, SimpleForm, 
    DateField, TextField, EditButton, DisabledInput, TextInput, LongTextInput, DateInput, BooleanField, BooleanInput, NumberField, NumberInput } from 'react-admin';
import {PostPagination} from '../PostPagination/PostPagination';
import BookIcon from 'material-ui/svg-icons/action/book';
export const FormsIcon = BookIcon;

const PostFilter = (props) => (
    <Filter {...props}>
        
        <TextInput label="id" source="id" ></TextInput>
        <TextInput label="company" source="company" ></TextInput>
        <TextInput label="weburl" source="weburl" ></TextInput>
        <TextInput label="E-mail" source="email" />
        <NumberInput label="Status" source="status" alwaysOn/>
    </Filter>
);

export const FormsList = (props) => (
    <List {...props} perPage={25} title="Lista kategorija" filters={<PostFilter />} >
        <Datagrid>
        <TextField source="id" />
            <TextField source="company" />
            <EmailField source="email" />
            <TextField source="weburl" />
            <NumberField source="status" />
            <DateField source="modifiedDateTime" />
            <EditButton basePath="/Forms" />
        </Datagrid>
    </List>
);

const FormTitle = ({ record }) => {
    return <span>Forma {record ? `"${record.id}"` : ''}</span>;
};


const IPUrl = ({ record }) => {
    return <a href={"https://www.iplocationtools.com/"+record.ipAddress}>Detalji</a>;
};


export const FormEdit = (props) => (
    <Edit title={<FormTitle />} {...props}>
        <SimpleForm>
        <TextField source="id" />
            <TextInput source="company" />
            <TextInput source="name" />
            <TextInput source="email" />
            <TextInput source="weburl" />
            <TextInput source="message" options={{ multiline: true }}/>
            <NumberInput source="status" />
            <TextField source="ipAddress" />{<IPUrl />}
            <DateField source="modifiedDateTime" />
        </SimpleForm>
    </Edit>
);

export const FormCreate = (props) => (
    <Create title="Kreiraj formu" {...props}>
        <SimpleForm>
        <TextInput source="company" />
            <TextInput source="name" />
            <TextInput source="email" />
            <TextInput source="weburl" />
            <TextInput source="message" options={{ multiline: true }}/>
            <NumberInput source="status" />
        </SimpleForm>
    </Create>
);
